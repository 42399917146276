window.Front = function () {
  this.init = function () {
    const whites = document.querySelectorAll('.front__line--post');
    const blacks = document.querySelectorAll('.front__line--past');

    for (let el of whites) {
      el.addEventListener('click', this._setRegular);
    }

    for (let el of blacks) {
      el.addEventListener('click', this._setInverted);
    }
  }

  this._setRegular = function (e) {
    document.body.classList.remove('inverted');
    sessionStorage.setItem('inverted', '0');
  }

  this._setInverted = function (e) {
    document.body.classList.add('inverted');
    sessionStorage.setItem('inverted', '1');
  }
}

new Front().init();
